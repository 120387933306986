<template>
  <div
    class="d-flex flex-column"
    style="height: 100%; overflow-y: auto"
    id="sidebarcontent"
  >
    <hr v-if="bottomAvatar" />
    <ul id="mainnav" class="nav nav-pills flex-column mb-auto">
      <li
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/"
          active-class="active"
          class="nav-link"
          id="homelink"
          aria-current="page"
          exact
        >
          <i class="bi-house" width="16" height="16"></i>
          Home
        </router-link>
      </li>
      <li
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/register"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-person-badge" width="16" height="16"></i>
          Registration
        </router-link>
      </li>
      <li
        v-if="
          yourReg &&
          yourReg.status == 2 &&
          (yourReg.isAlpha ||
            (dealerReg && dealerReg.tableType != 11 && dealerReg.status == 2))
        "
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/hotel"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-building" width="16" height="16"></i>
          Hotel
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="account.user && yourReg && yourReg.isAlpha && yourReg.status == 2"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/alpha"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-stars" width="16" height="16"></i>
          Alpha Benefits
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/charity"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-suit-heart" width="16" height="16"></i>
          Charity
        </router-link>
      </li>

      <li
        v-if="checkinOpen"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/checkin"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-clipboard-check" width="16" height="16"></i>
          Check-In Wizard
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/dancecomp"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-music-note-beamed" width="16" height="16"></i>
          Dance Competition
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/musicfestival"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-music-note-beamed" width="16" height="16"></i>
          LollapAWUza
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/dj" active-class="active" class="nav-link text-white">
          <i class="bi-vinyl-fill" width="16" height="16"></i>
          DJ
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/minors"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-people" width="16" height="16"></i>
          Minors & Guardians
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/panels"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-person-video3" width="16" height="16"></i>
          Panels
        </router-link>
      </li>
      <li
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/rewards"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-award" width="16" height="16"></i>
          Rewards
        </router-link>
      </li>
      <li
        v-if="yourReg && yourReg.status == 2"
        class="nav-item"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/volunteer"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-person-plus" width="16" height="16"></i>
          Volunteering
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isAlphaAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/alpha" active-class="active" class="nav-link">
          <i class="bi-stars" width="16" height="16"></i>
          Admin: Alphas
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isVolunteerAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/admin/volunteerapp"
          active-class="active"
          class="nav-link"
        >
          <i class="bi-person-lines-fill" width="16" height="16"></i>
          Admin: Volunteer Apps
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isPanelAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/charity" active-class="active" class="nav-link">
          <i class="bi-suit-heart-fill" width="16" height="16"></i>
          Admin: Charity
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isMinorAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/minor" active-class="active" class="nav-link">
          <i class="bi-people-fill" width="16" height="16"></i>
          Admin: Minors
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isPanelAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/panel" active-class="active" class="nav-link">
          <i class="bi-person-video3" width="16" height="16"></i>
          Admin: Panels
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user && (account.user.isDJAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/dj" active-class="active" class="nav-link">
          <i class="bi-vinyl" width="16" height="16"></i>
          Admin: DJ
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isDanceAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/dance" active-class="active" class="nav-link">
          <i class="bi-music-note-list" width="16" height="16"></i>
          Admin: Dance
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isDanceAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/admin/musicfestival"
          active-class="active"
          class="nav-link"
        >
          <i class="bi-music-note-list" width="16" height="16"></i>
          Admin: LollapAWUza
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="
          account.user &&
          (account.user.isDealerAdmin || account.user.isSuperAdmin)
        "
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/dealer" active-class="active" class="nav-link">
          <i class="bi-palette" width="16" height="16"></i>
          Admin: Dealer
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="account.user && account.user.isSuperAdmin"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/reg" active-class="active" class="nav-link">
          <i class="bi-person-badge-fill" width="16" height="16"></i>
          Admin: Reg
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="account.user && account.user.isSuperAdmin"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/merch" active-class="active" class="nav-link">
          <i class="bi-person-badge-fill" width="16" height="16"></i>
          Admin: Merch
        </router-link>
      </li>
      <li
        class="nav-item"
        v-if="account.user && account.user.isSuperAdmin"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link to="/admin/rewards" active-class="active" class="nav-link">
          <i class="bi-award" width="16" height="16"></i>
          Admin: Rewards
        </router-link>
      </li>
      <hr v-if="!bottomAvatar" style="background-color: white" />
      <li
        v-if="!bottomAvatar"
        class="nav-item"
        style="margin-left: auto"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/settings"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-gear" width="16" height="16"></i>
          Settings
        </router-link>
      </li>
      <li
        v-if="!bottomAvatar"
        class="nav-item"
        style="margin-left: auto"
        :data-bs-toggle="bottomAvatar ? null : 'offcanvas'"
        :data-bs-target="bottomAvatar ? null : '#navbarToggleOptions'"
      >
        <router-link
          to="/login"
          active-class="active"
          class="nav-link text-white"
        >
          <i class="bi-door-open" width="16" height="16"></i>
          Log Out
        </router-link>
      </li>
    </ul>
    <hr />
    <div v-if="bottomAvatar" class="dropdown">
      <a
        href="#"
        class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
        id="dropdownUser1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          :src="
            getAvatar(
              account.user && account.user.avatar
                ? account.user.avatar
                : 'Wolf.svg'
            )
          "
          alt=""
          width="32"
          height="32"
          class="rounded-circle me-2"
        />
        <strong class="text-truncate" v-if="account.user">{{
          account.user.username ? account.user.username : "New User"
        }}</strong>
      </a>
      <ul
        class="dropdown-menu dropdown-menu-dark text-small shadow"
        aria-labelledby="dropdownUser1"
      >
        <li>
          <router-link class="dropdown-item" to="/settings"
            >Settings</router-link
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <router-link class="dropdown-item" to="/login">Sign out</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SidebarInner",
  components: {},
  props: {
    bottomAvatar: Boolean,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      yourReg: (state) => state.register.yourRegistration,
      dealerReg: (state) => state.register.dealerRegistration,
      checkinOpen: (state) => state.togglz.togglz.checkinOpen,
    }),
  },
  created() {
    this.getTogglz();
  },
  methods: {
    getAvatar(src) {
      return require("../assets/avatars/" + src);
    },
    ...mapActions("togglz", {
      getTogglz: "getTogglz",
    }),
  },
};
</script>
<style lang="scss" scoped>
#homelink {
  color: orange;
}

#mainnav a {
  font-weight: bold;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2c599d;
}

.bi {
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

#navContainer {
  width: 280px;
}

#sidebarcontent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#sidebarcontent::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#sidebarcontent::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>
