import config from "config";
import { authHeader } from "../_helpers";

export const registerService = {
  hello,
  initial,
  logout,
  checkUsername,
  checkAlphas,
  addressAutofill,
  addressConfirmation,
  addressDeliverability,
  testReg,
  pedoCheck,
  adminSubmitReg,
  submitReg,
  cancelRegistration,
  submitVoucher,
  sendRegistrationCode,
  verifyRegistrationCode,
  pullExistingRegistration,
  getAll,
  getStats,
};

function hello() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/hello`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function initial() {
  // remove cookies to log user out
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/initial`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  // remove cookies to log user out
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/users/logout`, requestOptions).then(
    handleResponse
  );
}

function checkUsername(params) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), username: params.username },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/username`, requestOptions).then(
    handleResponse
  );
}

function checkAlphas() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/alphas`, requestOptions).then(
    handleResponse
  );
}

function addressAutofill(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ address: params.address, country: params.country }),
  };

  return fetch(`${config.apiUrl}/register/autofill`, requestOptions).then(
    handleResponse
  );
}

function addressConfirmation(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({ id: params.id }),
  };

  return fetch(`${config.apiUrl}/register/address`, requestOptions).then(
    handleResponse
  );
}

function addressDeliverability(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/deliverable`, requestOptions).then(
    handleResponse
  );
}

function adminSubmitReg(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/adminSubmit`, requestOptions).then(
    handleResponse
  );
}

function submitReg(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/submit`, requestOptions).then(
    handleResponse
  );
}

function cancelRegistration(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };
  return fetch(
    `${config.apiUrl}/register/cancelRegistration`,
    requestOptions
  ).then(handleResponse);
}

function testReg(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/test`, requestOptions).then(
    handleResponse
  );
}

function pedoCheck(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params), // RAWR this doesn't like params for some reason
  };
  return fetch(`${config.apiUrl}/register/background`, requestOptions).then(
    handleResponse
  );
}

function submitVoucher(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/voucher`, requestOptions).then(
    handleResponse
  );
}

function sendRegistrationCode(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/scode`, requestOptions).then(
    handleResponse
  );
}

function verifyRegistrationCode(params) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(params),
  };

  return fetch(`${config.apiUrl}/register/vcode`, requestOptions).then(
    handleResponse
  );
}

function pullExistingRegistration() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/existing`, requestOptions).then(
    handleResponse
  );
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/all`, requestOptions).then(
    handleResponse
  );
}

function getStats() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/register/stats`, requestOptions).then(
    handleResponse
  );
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }
    return data;
  });
}
