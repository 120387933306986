import config from "config";
import { authHeader } from "../_helpers";

export const dealerService = {
  clearNoAuto,
  getDealerApp,
  getAllDealerApps,
  submitDealerApp,
  sendDecision,
  setAssistant,
  deleteAssistant,
};

function clearNoAuto() {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
  };
  return fetch(`${config.apiUrl}/dealers/clearnoauto`, requestOptions).then(
    handleResponse
  );
}

function getDealerApp(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), id: id },
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/dealers`, requestOptions).then(handleResponse);
}

function getAllDealerApps() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(`${config.apiUrl}/dealers/all`, requestOptions).then(
    handleResponse
  );
}

function submitDealerApp(dealer) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(dealer),
  };
  return fetch(`${config.apiUrl}/dealers`, requestOptions)
    .then(handleResponse)
    .then((dealer) => {
      return dealer;
    });
}

function sendDecision(dealer) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(dealer),
  };
  return fetch(`${config.apiUrl}/dealers/decision`, requestOptions).then(
    handleResponse
  );
}

function setAssistant(assistant) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(assistant),
  };
  return fetch(`${config.apiUrl}/dealers/assistant`, requestOptions).then(
    handleResponse
  );
}

function deleteAssistant(assistant) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(assistant),
  };
  return fetch(`${config.apiUrl}/dealers/assistant`, requestOptions).then(
    handleResponse
  );
}

function handleNonTextResponse(response) {
  if (!response.ok) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      const error = (data && data.message) || response.statusText;
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      //console.clear();
      return Promise.reject(error);
    });
  }
  return response;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      //console.clear();
      return Promise.reject(error);
    }

    return data;
  });
}
