import { hotelService } from "../_services";
import { getField, updateField } from "vuex-map-fields";

const setState = () => {
  return {
    vrs: {
      lottConduct: false,
      lottGuarantee: false,
      lottResponsibility: false,
      newRoommateUsername: "",
      newRoommateDOB: "2000-01-01",
      loading: false,
      submitting: false,
      availableRooms: [
        {
          name: "Hilton Garden Inn: One King",
          code: "HGIK",
        },
        {
          name: "Hilton Garden Inn: Two Queens",
          code: "HGIQ",
        },
        {
          name: "Home2 Suites: Queen Bed + Sofabed",
          code: "H2SQ",
        },
        {
          name: "Hampton Inn: Two Queens",
          code: "HTNQ",
        },
      ],
      referenceRooms: [
        {
          name: "Hilton Garden Inn: One King",
          code: "HGIK",
        },
        {
          name: "Hilton Garden Inn: Two Queens",
          code: "HGIQ",
        },
        {
          name: "Home2 Suites: Queen Bed + Sofabed",
          code: "H2SQ",
        },
        {
          name: "Hampton Inn: Two Queens",
          code: "HTNQ",
        },
      ],
    },
    yourHotelApp: {
      checkIn: "2025-07-31",
      checkOut: "2025-08-04",

      ada: false,
      roommates: [],

      preferredRooms: [],
      groupLeader: undefined,
    },
    allhotelapps: {},
    allpendingshifts: {},
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  getHotelApp({ commit, dispatch }) {
    commit("getHotelAppRequest");

    hotelService.getHotelApp().then(
      (hotelApp) => commit("getHotelAppSuccess", hotelApp),
      (error) => {
        commit("getHotelAppFailure", error);
      }
    );
  },
  deleteHotelApp({ commit, dispatch }) {
    commit("deleteHotelAppRequest");

    hotelService.deleteHotelApp().then(
      (hotelApp) => {
        let msg = "Entry deleted";
        dispatch("alert/success", msg, {
          root: true,
        });
        commit("deleteHotelAppSuccess", hotelApp);
      },
      (error) => {
        commit("deleteHotelAppFailure", error);
      }
    );
  },
  getRoommate({ commit, dispatch, state }, username) {
    if (
      state.yourHotelApp.roommates &&
      state.yourHotelApp.roommates.length >= 3
    ) {
      dispatch(
        "alert/error",
        "You can only have a maximum of three roommates.",
        {
          root: true,
        }
      );
    } else if (
      state.yourHotelApp.preferredRooms.some(
        (z) => z.code == "HGIK" || z.code == "HTNK"
      ) &&
      state.yourHotelApp.roommates &&
      state.yourHotelApp.roommates.length >= 2
    ) {
      dispatch(
        "alert/error",
        "You cannot have more than two roommates with King rooms selected.",
        {
          root: true,
        }
      );
    } else {
      commit("getRoommateRequest");

      hotelService.getRoommate(username).then(
        (mate) => {
          if (
            state.yourHotelApp.roommates &&
            state.yourHotelApp.roommates.some((q) => q.id == mate.id)
          ) {
            dispatch("alert/error", "You've already added that person.", {
              root: true,
            });
          } else commit("getRoommateSuccess", mate);
        },
        (error) => {
          dispatch("alert/error", error, {
            root: true,
          });
          commit("getRoommateFailure", error);
        }
      );
    }
  },
  getAllHotelApps({ commit, dispatch }) {
    commit("getAllRequest");

    hotelService.getAllHotelApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  submitHotelApp({ commit, dispatch, state }, hotelApp) {
    if (
      state.yourHotelApp.preferredRooms.some(
        (z) => z.code == "HGIK" || z.code == "HTNK"
      ) &&
      state.yourHotelApp.roommates &&
      state.yourHotelApp.roommates.length > 2
    ) {
      dispatch(
        "alert/error",
        "You cannot have more than two roommates with King rooms selected.",
        {
          root: true,
        }
      );
    } else {
      commit("submitHotelAppRequest", hotelApp);

      hotelService.submitHotelApp(hotelApp).then(
        (volApp) => {
          //if the shirt size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
          commit("submitHotelAppSuccess", volApp);
          let msg = "Entry submitted successfully!";
          dispatch("alert/success", msg, {
            root: true,
          });
        },
        (error) => {
          commit("submitHotelAppFailure", {
            hotelApp,
            error: error.toString(),
          });
          dispatch("alert/error", error, {
            root: true,
          });
        }
      );
    }
  },
};

const getters = {
  getField,
};

const mutations = {
  updateField,
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  getHotelAppRequest(state) {
    state.vrs.loading = true;
  },
  getHotelAppSuccess(state, hotelApp) {
    if (hotelApp.app) {
      const originalOrder = hotelApp.app.preferredRooms;

      state.yourHotelApp = hotelApp.app;
      state.yourHotelApp.preferredRooms = state.vrs.availableRooms.filter((q) =>
        state.yourHotelApp.preferredRooms.some((p) => p == q.code)
      );
      state.yourHotelApp.preferredRooms = originalOrder.map((i) =>
        state.yourHotelApp.preferredRooms.find((j) => j.code === i)
      );
      state.vrs.availableRooms = state.vrs.availableRooms.filter(
        (i) => !hotelApp.app.preferredRooms.some((q) => q.code == i.code)
      );
      state.yourHotelApp.roommates = hotelApp.roommates;
    }

    state.vrs.loading = false;
  },
  getHotelAppFailure(state, error) {
    state.vrs.loading = false;
  },
  deleteHotelAppRequest(state) {
    state.vrs.submitting = true;
  },
  deleteHotelAppSuccess(state, hotelApp) {
    state.yourHotelApp = {
      checkIn: "2025-07-31",
      checkOut: "2025-08-04",

      ada: false,
      roommates: [],

      preferredRooms: [],
      groupLeader: undefined,
    };
    state.vrs.submitting = false;
  },
  deleteHotelAppFailure(state, error) {
    state.vrs.submitting = false;
  },
  getRoommateRequest(state) {
    state.vrs.submitting = true;
    state.vrs.newRoommateDOB = "2000-01-01";
    state.vrs.newRoommateUsername = "";
  },
  getRoommateSuccess(state, roommate) {
    if (!state.yourHotelApp.roommates) state.yourHotelApp.roommates = [];
    state.yourHotelApp.roommates.push(roommate);
    state.vrs.submitting = false;
  },
  getRoommateFailure(state, error) {
    state.vrs.submitting = false;
  },
  getAllRequest(state) {
    state.allhotelapps = { loading: true };
  },
  getAllSuccess(state, hotelApps) {
    state.allhotelapps = { items: hotelApps };
  },
  getAllFailure(state, error) {
    state.allhotelapps = { ...state.allhotelapps, error };
  },
  submitHotelAppRequest(state, hotelApp) {
    state.vrs.submitting = true;
  },
  submitHotelAppSuccess(state, hotelApp) {
    if (hotelApp.app) {
      const originalOrder = hotelApp.app.preferredRooms;
      state.yourHotelApp = hotelApp.app;
      state.yourHotelApp.preferredRooms = state.vrs.referenceRooms.filter((q) =>
        state.yourHotelApp.preferredRooms.some((p) => p == q.code)
      );
      state.yourHotelApp.preferredRooms = originalOrder.map((i) =>
        state.yourHotelApp.preferredRooms.find((j) => j.code === i)
      );
      state.vrs.availableRooms = state.vrs.referenceRooms.filter(
        (i) => !hotelApp.app.preferredRooms.some((q) => q.code == i.code)
      );
      state.yourHotelApp.roommates = hotelApp.roommates;
    }
    state.vrs.submitting = false;
  },
  updateHotelAppSuccess(state, hotelApp) {
    state.allhotelapps.items = state.allhotelapps.items.map((app) => {
      if (app.attendeeID == hotelApp.attendeeID) {
        return { ...app, ...hotelApp };
      }
      return app;
    });
  },
  submitHotelAppFailure(state, error) {
    state.vrs.submitting = false;
  },
};

export const hotel = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
