import { dealerService } from "../_services";

const setState = () => {
  return {
    yourDealerApp: {},
    allDealerApps: {},
    loading: false,
  };
};

const state = setState();

const actions = {
  resetState({ commit }) {
    commit("resetStateNow");
  },
  clearNoAuto({ commit, dispatch }) {
    commit("clearNoAutoRequest");

    dealerService.clearNoAuto().then(
      (users) => commit("clearNoAutoSuccess", users),
      (error) => {
        commit("clearNoAutoFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  getDealerApp({ commit, dispatch }, id) {
    commit("getDealerAppRequest");

    dealerService.getDealerApp(id).then(
      (dealerApp) => commit("getDealerAppSuccess", dealerApp),
      (error) => {
        commit("getDealerAppFailure", error);
      }
    );
  },
  getAllDealerApps({ commit, dispatch }) {
    commit("getAllRequest");

    dealerService.getAllDealerApps().then(
      (users) => commit("getAllSuccess", users),
      (error) => {
        commit("getAllFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
  sendDecision({ commit, dispatch }, dealer) {
    commit("sendDecisionRequest");

    dealerService.sendDecision(dealer).then(
      (dealerApp) => {
        commit("sendDecisionSuccess", dealerApp);
        dispatch("alert/success", "Approved successfully!", {
          root: true,
        });
      },
      (error) => {
        commit("sendDecisionFailure", error);
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },

  setAssistant({ commit, dispatch, state }, params) {
    commit("setAssistantRequest");
    //if (true == false)
    //  dispatch("alert/error", error, {
    //    root: true,
    //  });
    //else {
    dealerService.setAssistant(params).then(
      (username) => {
        commit("setAssistantRequest");
        commit("register/pullExistingRegistrationSuccess", username, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, {
          root: true,
        });
        commit("setAssistantFailure");
      }
    );
    //}
  },

  deleteAssistant({ commit, dispatch, state }, params) {
    commit("deleteAssistantRequest");
    //if (true == false)
    //  dispatch("alert/error", error, {
    //    root: true,
    //  });
    //else {
    dealerService.deleteAssistant(params).then(
      (username) => {
        commit("deleteAssistantRequest");
        commit("register/pullExistingRegistrationSuccess", username, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, {
          root: true,
        });
        commit("deleteAssistantFailure");
      }
    );
    //}
  },

  submitDealerApp({ commit, dispatch }, dealerApp) {
    commit("submitDealerAppRequest", dealerApp);

    dealerService.submitDealerApp(dealerApp).then(
      (danceApp) => {
        //if the dealerName size parameter exists, it's a cheesy way of saying this is a full app and not an admin panel update
        if (dealerApp.dancerName) commit("submitDealerAppSuccess", danceApp);
        else commit("updateDealerAppSuccess", danceApp);
        let msg =
          "Application " +
          (dealerApp.dancerName ? "submitted" : "updated") +
          " successfully!";
        dispatch("alert/success", msg, {
          root: true,
        });
      },
      (error) => {
        commit("submitDealerAppFailure", {
          dealerApp,
          error: error.toString(),
        });
        dispatch("alert/error", error, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  resetStateNow(state) {
    Object.assign(state, setState());
  },
  updateDancerName(state, value) {
    state.yourDealerApp.dancerName = value;
  },
  updateIntro(state, value) {
    state.yourDealerApp.intro = value;
  },
  clearNoAutoRequest(state) {},
  clearNoAutoSuccess(state, dealerApps) {
    state.allDealerApps = {
      items: dealerApps
        .map((app) => {
          if (dealerApps.some((q) => q.Dealer.assistantOf == app.attendeeID)) {
            return {
              ...app,
              Dealer: {
                ...app.Dealer,
                Assistants: dealerApps.filter(
                  (b) => b.Dealer.assistantOf == app.attendeeID
                ),
              },
            };
          }
          return app;
        })
        .filter((z) => !z.Dealer.assistantOf),
    };
  },
  clearNoAutoFailure(state) {},
  getDealerAppRequest(state) {
    state.yourDealerApp = { loading: true };
  },
  getDealerAppSuccess(state, dealerApp) {
    if (dealerApp) {
      state.yourDealerApp = dealerApp;
    } else state.yourDealerApp = {};
  },
  getDealerAppFailure(state, error) {
    state.yourDealerApp = {};
  },
  getAllRequest(state) {
    state.allDealerApps = { loading: true };
  },
  getAllSuccess(state, dealerApps) {
    state.allDealerApps = {
      items: dealerApps
        .map((app) => {
          if (dealerApps.some((q) => q.Dealer.assistantOf == app.attendeeID)) {
            return {
              ...app,
              Dealer: {
                ...app.Dealer,
                Assistants: dealerApps.filter(
                  (b) => b.Dealer.assistantOf == app.attendeeID
                ),
              },
            };
          }
          return app;
        })
        .filter((z) => !z.Dealer.assistantOf),
    };
  },
  setAssistantRequest(state) {
    state.loading = true;
  },
  setAssistantFailure(state) {
    state.loading = false;
  },
  setAssistantSuccess(state) {
    state.loading = false;
  },
  deleteAssistantRequest(state) {
    state.loading = true;
  },
  deleteAssistantFailure(state) {
    state.loading = false;
  },
  deleteAssistantSuccess(state) {
    state.loading = false;
  },
  getAllFailure(state, error) {
    state.allDealerApps = { ...state.allDealerApps, error };
  },
  submitDealerAppRequest(state, dealerApp) {
    state.yourDealerApp = { ...state.yourDealerApp, submitting: true };
  },
  submitDealerAppSuccess(state, dealerApp) {
    state.yourDealerApp = dealerApp;
  },
  updateDealerAppSuccess(state, dealerApp) {
    state.allDealerApps.items = state.allDealerApps.items.map((app) => {
      if (app.attendeeID == dealerApp.attendeeID) {
        return { ...app, ...dealerApp };
      }
      return app;
    });
  },
  submitDealerAppFailure(state, error) {
    state.yourDealerApp = { ...state.yourDealerApp, error };
  },
  sendDecisionRequest(state) {},
  sendDecisionSuccess(state, dealerApp) {
    state.allDealerApps.items = state.allDealerApps.items.map((app) => {
      if (app.attendeeID == dealerApp.attendeeID) {
        return { ...app, ...dealerApp };
      }
      return app;
    });
  },
  sendDecisionFailure(state, error) {
    state.yourDealerApp = {};
  },
};

export const dealer = {
  namespaced: true,
  state,
  actions,
  mutations,
};
